import { Typography, Button, IconButton } from "@mui/material"
import { Box, Container} from "@mui/system"
import Stack from '@mui/material/Stack';
import Brightness4Icon from "@mui/icons-material/Brightness4"
import Brightness7Icon from '@mui/icons-material/Brightness7'

import { useState, useContext } from "react"
import { ThemeSettingsContext } from "../contexts/ThemeSettingsContext"
import Signin from "../features/auth/Signin"
import Signup from "../features/auth/Signup"
import {ReactComponent as OneLine} from '../imgs/oneline.svg'
import './Landing.css'



export default function Landing() {
  const [signInSelected, setSignInSelected] = useState(true)
  const {toggleTheme, isDarkTheme} = useContext(ThemeSettingsContext)
  return (
    <Box component="article">  
      <Container 
      component='header'
        sx={{
          mt:4,
          pb:2,
          borderRadius: 2,  
        }}
      >
        <Container component='figure' disableGutters sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
        }} >
        <IconButton 
            onClick={toggleTheme}
            color="inherit">
              {isDarkTheme?<Brightness4Icon/>:<Brightness7Icon/>}
            </IconButton>
            </Container>
        <Typography variant='h1' align="center" sx={isDarkTheme?{}:{color:'primary.main'}}>Interludes</Typography>
        <Typography variant='subtitle1' align='center'>play out downtime between rpg sessions</Typography>
        <Box>
          <OneLine id='oneline'  stroke={isDarkTheme?'#E8E4EE':'black'}/>
        </Box>
        
      </Container>
      <Container disableGutters component="main"  maxWidth="xs" 
      sx={{
      mt:4,
      backgroundColor: 'background.paper',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems:'center',
      borderRadius: 2,
      boxShadow: "2px 2px 2px #1f0f36, -2px -2px 2px #29154a;"
    }}>
        <Container disableGutters sx={{
      //backgroundColor: 'secondary.light',
      display: 'flex',
      justifyContent: 'center'
    }}>
          <Button fullWidth onClick={()=>setSignInSelected(true)}
          aria-label="navigate to sign in"
          sx={signInSelected?
            {
              backgroundColor:'background.paper',
              color: 'text.primary',
              boxShadow: "-2px -2px 2px #29154a;"
            }
          :{
            borderTopRightRadius: 0,
            backgroundColor: 'background.default',
            boxShadow: "-2px -3px 3px #211F24"
          }
          }
           
          >Sign In</Button>
          <Button fullWidth onClick={()=>setSignInSelected(false)}
          aria-label='navigate to sign p'
            sx={signInSelected?
              {
                borderTopLeftRadius: 0,
              backgroundColor: 'background.default',
              boxShadow: "-2px -3px 3px #211F24"
              }
            :{
              backgroundColor: 'background.paper',
              color: 'text.primary'
            }
            }
        >
            Sign Up</Button>
        </Container>
        <Container>
      {signInSelected?
      <Signin/>
      :<Signup/>
      }
    </Container>
      </Container>

    <Container component='main'>
      <Box m={2} sx={{
        
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
        }}>
        <Stack mt={2}>
          <Typography sx={{
            color: 'text.primary',
            textDecoration: 'underline',
            textDecorationColor: '#60E82A',
            }} variant="h5">
            What is Interludes?
          </Typography>
          <Box sx={{maxWidth:"600px"}}>
            <Typography variant="body2">
              Interludes is a downtime session manager. It can be used with any tabletop role-playing game
            </Typography>
          </Box>
        </Stack>
        
        <Stack mt={2}>
        <Typography sx={{
          textDecoration: 'underline',
          textDecorationColor: '#60E82A',
          }} variant="h5">
          What is a downtime session?
        </Typography>
        <Box sx={{maxWidth:"600px"}}>
          <Typography variant="body2">
            Downtime takes place between full gaming sessions. Traditionally downtime sessions are used for character improvement and/or a solo action to either use an item or skill gained from the previous game session, or to prepare for the following session.
          </Typography>
        </Box>
        </Stack>
      
        <Stack mt={2}>
          <Typography sx={{
          
            textDecoration: 'underline',
            textDecorationColor: '#60E82A',
            }} variant="h5">
            What is an Interlude?
          </Typography>
          <Box sx={{maxWidth:"600px"}}>
            <Typography variant="body2" sx={{textAlign:'left'}}>
              An Interlude is created by the Dungeon Master to set the general scene and prompt the players to attempt an Act.
            </Typography>
          </Box>
        </Stack>

        <Stack mt={2}>
          <Typography sx={{
            
            textDecoration: 'underline',
            textDecorationColor: '#60E82A',
            }} variant="h5">
            What is an Act?
          </Typography>
          <Box sx={{maxWidth:"600px"}}>
            <Typography variant="body2">
              An act is an action or activity the player would like to attempt. Downtime sessions are short and aren't played out extensively like game sessions. An act is a singular event that is resolved with one die roll.
            </Typography>
          </Box>
        </Stack>

        <Stack mt={2}>
          <Typography sx={{
          
            textDecoration: 'underline',
            textDecorationColor: '#60E82A',
            }} variant="h5">
            How does this all work exactly?
          </Typography>
          <Box sx={{maxWidth:"600px"}}>
            <Typography variant="body2">
              1. The Dungeon Master creates an Interlude and Prompts the players with a general scene.
            </Typography>
            <Typography variant="body2">
              2. Each player declares an Act for their player to do during the downtime session.
            </Typography>
            <Typography variant="body2">
              3. The Dungeon Master sets the roll type and the Difficulty Challenge, if applicable.
            </Typography>
            <Typography variant="body2">
              4. The player rolls.
            </Typography>
            <Typography variant="body2">
              5. Both the Dungeon Master and the player have a chance to narrate out what the result was and how the player reacts to the result.
            </Typography>
          </Box>
        </Stack>

        <Stack mt={2}>
          <Typography sx={{
         
            textDecoration: 'underline',
            textDecorationColor: '#60E82A',
            }} variant="h5">
            But why??
          </Typography>
          <Box sx={{maxWidth:"600px"}}>
            <Typography variant="body2">
              Because it's fun to have a chance to check in with your fantasy world in between actual sessions of play, gives the chance for some one on one interaction to happen, and possibly can create a hook or narrative element for the next gaming session.
            </Typography>
          </Box>
        </Stack>
        
      
      </Box>
    </Container>

    </Box>
  )
}
